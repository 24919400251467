.welcome {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 100px 0;

    &::after {
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: radial-gradient(circle,rgba(207, 0, 0, 0.23) 0,hsla(0,0%,100%,0) 39%),radial-gradient(circle,rgba(87,41,255,.232) 0,hsla(0,0%,100%,0) 50%);
        background-position-x: -40vw,40vw;
        background-position-y: 0,-15vh;
        background-repeat: no-repeat;
    }

    &>h1 {
        font-size: 4.5rem;
    }
    &>p {
        font-size: 2rem;
    }

    &>.actions {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        &>button {
            width: fit-content;
        };
    }
}

.statistic {
    .badge {
        margin-bottom: 15px;
    }
}