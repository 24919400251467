.form {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.form-row {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.form-row+.form-row {
    margin-top: var(--global-margin);
}

.order-submit {
    display: flex;
    flex-direction: column;
}

