.header {
    width: 100%;
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;

    .actions {
        display: flex;
        align-items: center;
        &>a+a {
            margin-left: 10px;
        }
    }
}

.logo {
    display: flex;
    align-items: center;
    font-weight: 700;
    text-decoration: none;
    
    .logo-square {
        height: 20px;
        width: 20px;
        background: var(--gradient);
        border-radius: var(--border-radius);
        margin-right: 5px;
    }
}
