.services-table {
    padding: 50px 0;
}

.filters {
    padding: 15px 0;
}

.table-container {
    max-width: 100%;
    overflow-x: auto;
}

table {
    width: 1300px;
    margin-bottom: 20px;
    font-size: 14px;
}

thead {
    background-color: var(--bg-gray);
}

thead th {
    text-align: left;
    padding: 8px 12px;
    white-space: nowrap;
}

tbody td {
    padding: 8px 12px;
    border: 1px solid var(--bg-gray);
    white-space-collapse: break-spaces;
}

td {
    white-space: normal; /* Разрешить перенос текста на новую строку */
  max-width: 600px;
}

tbody tr:nth-child(even) {
    background-color: var(--bg-light-gray);
}

.sort-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: var(--primary);
}

.sort-button:hover {
    text-decoration: underline;
}

label {
    margin-right: 10px;
}

select {
    padding: 4px;
}