:root {
  --white: #fff;
  --black: #040614;
  --primary: #5021ff;

  --bg-gray: #e7edf1;
  --bg-light-gray: #f3f5f8;
  --dark-gray: #3c4055;

  --secondary: rgba(207, 0, 0, 0.23);
  --red: #dd3f53;
  --background: var(--white);

  --border-radius: 4px;
  --secondary-border-radius: 8px;
  --border-radius-circle: 100px;

  --global-margin: 20px;

  --gradient: linear-gradient(88deg, var(--primary) 0, var(--secondary) 100%);
  --gradient-red: linear-gradient(275.9deg, #E42173 5.4%, #FF3939 48.25%, #FF7324 85.17%);

  --font-family: 'Roboto Mono', sans-serif;
  --font-size-base: 16px;
  --line-height-base: 1.5;
  --font-weight-normal: 400;
  --font-weight-bold: 700;
}

body {
  font-family: var(--font-family);
  font-weight: var(--font-weight-normal);
  font-style: normal;
  font-size: var(--font-size-base);
  line-height: var(--line-height-base);
  background: var(--background);
  color: var(--black);
  padding: 0;
}


ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

h1 {
  font-size: 3rem;
  font-weight: var(--font-weight-bold);
  line-height: 1.2;
  margin: 0;

  &.title {
    font-size: 4rem;
  }
}

h2 {
  font-size: 2rem;
  font-weight: var(--font-weight-bold);
  line-height: 1.3;
  margin: 0;
}

h3 {
  font-size: 1.5rem;
  font-weight: var(--font-weight-bold);
  line-height: 1.4;
  margin: 0;
}

h4 {
  font-size: 1.2rem;
  font-weight: var(--font-weight-bold);
  line-height: 1.5;
  margin:
    0;
}

label {
  display: block;
  font-size: 13px;
  margin-bottom: 5px;
}

a {
  color: var(--primary);
  text-decoration: underline;
  cursor: pointer;

  &.a--black {
    color: var(--black);
    text-decoration: none;
  }

  &.a--gray {
    color: var(--dark-gray);

    &:hover {
      color: var(--primary);
    }
  }
}

.bold {
  font-weight: var(--font-weight-bold);
}

.color-gray {
  color: var(--black);
  opacity: .5;
}

.color-red {
  color: var(--red);
}

.primary-color {
  color: var(--primary);
}

.content {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content-width {
  max-width: 800px;
  margin: 0 auto;
}

.fg-1 {
  flex-grow: 1;
}

.card {
  background: var(--bg-gray);
  padding: 15px;

  &>.card-section+.card-section {
    margin-top: var(--global-margin);
  }
  &.card--black {
    background-color: var(--black);
    color: var(--white);
  }

  &.card--light-gray {
    background: var(--bg-light-gray);
  }

  &.card--dark-gray {
    background: var(--dark-gray);
  }
}

.badge {
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--bg-gray);
  color: var(--primary);
  border-radius: var(--secondary-border-radius);
}

.tag {
  justify-content: center;
  align-items: center;
  padding: 2px 7px;
  background: var(--gradient);
  border-radius: 19px;
  color: var(--white);
  font-size: 14px;

  &.red {
    background: var(--gradient-red);
  }
}

.list {
  max-width: 300px;
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.list-item {
  display: flex;
  justify-content: space-between;
}

.section {
  padding: 30px 0;
}

.input {
  width: 100%;
  padding: 8px 14px;
  border: 1px solid #94a3b8;
  filter: drop-shadow(0px 0px 1px rgb(209, 213, 219));
  border-radius: var(--border-radius);

  &:active {
    --tw-ring-color: rgb(79 70 229);
  }
}

.input-help {
  color: var(--black);
  opacity: .5;
}

.mt {
  margin-top: var(--global-margin);
}

.radio-button {
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: var(--border-radius);
  border: 1px solid var(--bg-gray);
  background-color: var(--bg-gray);
  padding: 15px;
  cursor: pointer;

  &:hover {
    border-color: var(--primary);
  }

  & > input:checked + & {
    border-color: var(--primary);
  }

  &>input {
    margin-right: 15px;
  }
}

.btn {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  background-color: var(--primary);
  color: #fff;
  border-radius: var(--border-radius);
  padding: 20px;
  border-width: 0;
  cursor: pointer;
  transition: transform 0.1s;
  white-space: nowrap;

  &:hover, &:active, &:focus {
    color: #fff;
    text-decoration: none;
  }

  &>* {
    margin-right: 10px;
  }

  &.btn--small {
    padding: 10px;
  }

  &.btn--tiny {
    padding: 5px 10px;
    font-size: 13px;
  }

  &.btn--black {
    background-color: var(--black);
  }

  &.btn--secondary {
    background-color: var(--secondary);
  }

  &.btn--dark-gray {
    background-color: var(--dark-gray);
    color: var(--white);
  }

  &:active {
    transform: scale(0.99);
  }

  &:hover {
    opacity: .9;
  }
  
  &:disabled {
    background-color: var(--bg-gray);
    color: var(--black);
  }
}