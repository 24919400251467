.footer {
    background-color: var(--bg-light-gray);
    padding: 25px 0;
    margin-top: 40px;

    & .btn {
        width: fit-content;
        &:hover {

        }
    }
    & a {
        font-size: 13px;
        text-decoration: none;
    }
    .payment-icons {
        display: flex;
        align-items: center;
        margin-top: 10px;

        & img {
            fill: var(--dark-gray);
            margin-right: 10px;
        }
    }
    .org-info {
        font-size: 13px;
        margin-top: var(--global-margin);
        opacity: .5;
        &>p{ margin: 0;}
    }
}

