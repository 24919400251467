.balance-history {
    &>.item {
        padding: 15px;
        display: flex;
        flex-direction: column;

        &+.item {
            border-top: 1px solid var(--bg-gray);
        }

        &>div {
            display: flex;
            justify-content: space-between;
        }

        &>.history-item-amount {
            font-weight: 700;
            font-size: 18px;
        }

        .date {
            font-size: 13px;
            color: var(--black);
            opacity: .5;
        }
    }

}