.summary {
        font-size: 14px;
    &>div {
        display: flex;
        justify-content: space-between;
        &+div {
            margin-top: 5px;
        }
    }
}
.total-price {
    display: flex;
    justify-content: space-between;
}

.offer {
    font-size: 14px;
    margin-top: 10px;
    display: flex;
    width: fit-content;
    align-items: center;

    &>div {
        margin-left: 10px;
    }
}

.discount>div {
    display: flex;
    justify-content: space-between;

    &+div {
        margin-top: 5px;
    }
}