.faq {
    &>h2 {
        margin-bottom: var(--global-margin);
    }
    .faq-item {
        padding: 15px ;
        display: block;
        background-color: var(--bg-light-gray);
        border-radius: var(--secondary-border-radius);
        cursor: pointer;
        font-size: 18px;

        &:hover {
            background-color: var(--bg-gray);
        }
        
        &>div {
            display: flex;
            align-items: center;
        }
        &>div::before {
            border-radius: 2rem;
            content: "";
            display: inline-block;
            margin-right: 15px;
            min-height: 3.2rem;
            min-width: .6rem;
            background-color: var(--primary);
        }
        &+& {
            margin-top: 10px;
        }
    }
}